<!-- eslint-disable max-len -->
<template>
  <svg viewBox="0 0 2321 1147" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_494_96)">
      <path d="M2320.86 0.0634766H1174.72V1146.21H2320.86V0.0634766Z" fill="#008F99" />
      <path d="M1544.67 580.864H1503.17V804.366H1544.67V580.864Z" fill="white" />
      <path
        d="M1404.1 377.333H1410.03C1433.15 377.333 1450.64 382.677 1450.64 410.243C1450.64 437.515 1433.76 444.632 1409.73 444.632H1404.1V377.333ZM1404.1 475.162H1404.69L1458.95 565.284H1511.7L1448.57 471.021C1478.51 465.99 1494.22 437.525 1494.22 409.066C1494.22 361.338 1463.69 341.777 1419.52 341.777H1360.53V565.284H1404.1V475.162Z"
        fill="white"
      />
      <path
        d="M1404.1 616.634H1410.03C1433.15 616.634 1450.64 621.977 1450.64 649.543C1450.64 676.816 1433.76 683.933 1409.73 683.933H1404.1V616.634ZM1404.1 714.463H1404.69C1404.69 714.463 1420.31 715.729 1430.19 714.463C1437.49 713.527 1448.57 710.321 1448.57 710.321C1478.51 705.291 1494.22 676.826 1494.22 648.366C1494.22 600.639 1463.69 581.078 1419.52 581.078H1360.53V804.585H1404.1V714.463Z"
        fill="white"
      />
      <path
        d="M1536.31 383.259C1541.17 383.259 1545.93 381.816 1549.97 379.113C1554.02 376.41 1557.17 372.568 1559.03 368.072C1560.9 363.577 1561.38 358.631 1560.43 353.858C1559.48 349.086 1557.14 344.703 1553.7 341.262C1550.26 337.822 1545.88 335.479 1541.1 334.529C1536.33 333.58 1531.39 334.067 1526.89 335.929C1522.4 337.791 1518.55 340.944 1515.85 344.99C1513.15 349.036 1511.7 353.792 1511.7 358.658C1511.7 361.891 1512.33 365.093 1513.56 368.081C1514.8 371.069 1516.61 373.784 1518.89 376.07C1521.18 378.356 1523.89 380.167 1526.88 381.401C1529.87 382.635 1533.07 383.266 1536.31 383.259Z"
        fill="white"
      />
      <path d="M1557.05 419.729H1515.55V565.275H1557.05V419.729Z" fill="white" />
      <path
        d="M1642.43 451.729C1663.79 451.729 1676.81 473.082 1676.81 492.638C1676.81 512.194 1663.78 533.26 1642.43 533.26C1621.09 533.26 1608.04 512.199 1608.04 492.638C1608.04 473.077 1621.08 451.729 1642.43 451.729ZM1631.46 570.007C1639.11 570.007 1646.65 568.272 1653.53 564.934C1660.42 561.596 1666.45 556.741 1671.18 550.735H1671.79V560.225C1671.79 582.154 1671.18 607.947 1641.84 607.947C1634.68 608.223 1627.7 605.726 1622.34 600.976C1616.99 596.227 1613.67 589.592 1613.09 582.457H1569.21C1571.29 621.291 1607.16 642.937 1642.73 642.937C1697.28 642.937 1713.28 603.801 1713.28 556.376V419.723H1671.79V433.628H1671.18C1666.73 427.872 1661.02 423.201 1654.5 419.967C1647.98 416.732 1640.81 415.017 1633.53 414.951C1590.25 414.951 1565.95 453.487 1565.95 493.512C1565.95 532.634 1588.79 569.988 1631.46 569.988"
        fill="white"
      />
      <path d="M1146.14 0.0634766H0V1146.21H1146.14V0.0634766Z" fill="#008F99" />
      <path
        d="M429.553 536.114L353.331 406.23H390.64C465.343 406.23 475.418 440.123 475.418 473.27C475.418 508.762 461.558 528.059 429.553 536.074M546.933 473.27C546.933 424.058 526.637 341.534 390.64 341.534H236.671L352.606 540.33H249.195V804.697H320.705V605.036H390.228L505.437 804.697H585.692L463.629 594.409C517.559 576.035 546.918 533.741 546.918 473.31"
        fill="white"
      />
      <path
        d="M610.81 538.867V804.545H682.816V603.424H782.134C853.442 603.424 911.248 544.376 911.248 473.069C911.248 401.761 853.442 342.713 782.134 342.713H610.81V407.27H777.168C810.08 407.27 836.759 433.95 836.759 466.861V479.276C836.759 512.187 810.08 538.867 777.168 538.867H610.81Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1623.94 653.083C1613.38 653.083 1603.8 654.944 1595.23 658.716C1586.65 662.406 1578.9 668.143 1571.96 675.863C1565.5 682.908 1560.54 691.062 1557.06 700.303C1553.59 709.553 1551.85 719.326 1551.85 729.603C1551.85 751.45 1558.75 769.902 1572.6 784.787L1572.61 784.798C1586.56 799.606 1603.97 807.027 1624.68 807.027C1635.59 807.027 1644.74 805.314 1652.01 801.735C1656.89 799.357 1661.04 796.11 1664.45 792.018V803.797H1699.84V656.701H1664.08V668.866C1660.24 664.511 1655.68 661.049 1650.4 658.5C1642.92 654.854 1634.07 653.083 1623.94 653.083ZM1599.74 701.986C1606.84 694.538 1615.31 690.868 1625.29 690.868C1634.69 690.868 1642.92 694.687 1650.06 702.584L1650.08 702.603C1657.28 710.401 1660.87 719.701 1660.87 730.636C1660.87 740.847 1657.34 749.721 1650.21 757.368C1643.06 765.026 1634.95 768.725 1625.79 768.725C1615.56 768.725 1606.93 765.008 1599.75 757.488C1592.67 749.891 1589.09 740.481 1589.09 729.086C1589.09 718.327 1592.64 709.349 1599.73 701.995L1599.74 701.986Z"
        fill="white"
      />
      <path
        d="M1779.64 653.859C1771.66 653.859 1764.44 655.482 1758.05 658.788L1758.04 658.795C1753.61 661.115 1749.57 664.296 1745.91 668.301V656.702H1710.14V803.798H1745.54V740.457C1745.54 721.561 1747.85 708.803 1752.09 701.669L1752.09 701.659C1756.17 694.712 1762.82 691.128 1772.62 691.128C1782.26 691.128 1788.54 694.392 1792.16 700.506C1794.03 703.656 1795.53 708.336 1796.56 714.718C1797.58 721.07 1798.1 728.951 1798.1 738.39V803.798H1834.6V726.115C1834.6 713.415 1833.9 703.607 1832.46 696.802C1831.09 689.927 1828.78 683.755 1825.5 678.316L1825.48 678.284C1820.43 670.203 1814.04 664.068 1806.31 659.966C1798.66 655.864 1789.74 653.859 1779.64 653.859Z"
        fill="white"
      />
      <path d="M1146.14 0.0644531H0V1146.21H1146.14V0.0644531Z" fill="#008F99" />
      <path
        d="M429.554 536.114L353.332 406.23H390.641C465.343 406.23 475.419 440.123 475.419 473.27C475.419 508.762 461.559 528.059 429.554 536.074M546.934 473.27C546.934 424.058 526.638 341.534 390.641 341.534H236.672L352.607 540.33H249.196V804.697H320.706V605.036H390.229L505.438 804.697H585.693L463.63 594.409C517.56 576.035 546.919 533.741 546.919 473.31"
        fill="white"
      />
      <path
        d="M610.81 538.867V804.545H682.816V603.424H782.134C853.442 603.424 911.248 544.376 911.248 473.069C911.248 401.761 853.442 342.713 782.134 342.713H610.81V407.27H777.168C810.08 407.27 836.759 433.95 836.759 466.861V479.276C836.759 512.187 810.08 538.867 777.168 538.867H610.81Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_494_96">
        <rect width="2320.86" height="1146.14" fill="white" transform="translate(0 0.0644531)" />
      </clipPath>
    </defs>
  </svg>
</template>
